import { useEffect } from 'react'
import { captureUTMParameters } from 'utils/utm'

const UTMCapture = () => {
  useEffect(() => {
    captureUTMParameters()
  }, [])

  return null
}

export default UTMCapture
